<template>
  <Breadcrumb :urls="urls"  :translationloaded="translationloaded" :translate="translate"></Breadcrumb>
  <WhatsAppButton></WhatsAppButton>
  <div class="account-info-sec" v-if="translationloaded==true">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div id="show-hidden-menu" class="dashBoarsBtnMain"  @click="toggledashboard()">
            <i class="fa fa-th-large" aria-hidden="true"></i>{{translate('dashboard')}}
          </div>
          <div id ="vdasboard" class="hidden-menu" style="display: none;">
          <SideBar
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideBar>
          </div>
          <div class="mainSideDiplay">
           <SideBar
              :translationloaded="translationloaded"
              :translate="translate"
            ></SideBar>
          </div>
        </div>
        <div class="col-lg-9">
          <OrderList
            :orderData="fetctCustomerOrderlist"
            :web_admin_url="web_admin_url"
            @openOrderDetail="openOrderDet"
            :translationloaded="translationloaded"
            :translate="translate"
          >
          </OrderList>
          <EmptyItem v-if="fetctCustomerOrderlist.length < 1"></EmptyItem>
        </div>
      </div>
    </div>
  </div>
  <OrderDetail
    :openStatus="orderDetailopenStaus"
    :web_admin_url="web_admin_url"
    :orderDetail="orderDetails"
    :currency="currency"
    @closeOrderDet="closeOrderDetail"
    :ajax_status="ajax_status"
     @openOrderReview="openOrderReview"
    :translationloaded="translationloaded"
    :translate="translate"
  >
  </OrderDetail>
  <ProductReview :review_open_status="review_open_status" :review_product_url_key="review_product_url_key" @close_review_popup="close_review_popup" :translationloaded="translationloaded"
  :translate="translate" ></ProductReview>
  <div class="menu-overlay  "></div>
    <span class="scrollTop" @click="topToBottom">
                          <i class="fa fa-angle-up" aria-hidden="true"></i>
    </span>
</template>
<script>
import Breadcrumb from "../components/Breadcrumb";
import SideBar from "../components/my_account/SideBar";
import OrderList from "../components/my_account/OrderList";
import OrderDetail from "@/components/my_account/OrderDetail";
import Config from "../config";
import EmptyItem from "@/components/EmptyItem";
import { mapGetters, mapActions } from "vuex";
import helper from "@/helper/helper";
import ProductReview from '@/components/ProductReview'
import WhatsAppButton from "@/components/WhatsAppButton";

import $ from "jquery";
export default {
  name: "MyOrder",
  components: {
    Breadcrumb,
    SideBar,
    OrderList,
    OrderDetail,
    EmptyItem,
    ProductReview,
    WhatsAppButton
  
  },
  props:[
    'translationloaded',
    'translate'
  ],
  data() {
    return {
      urls: [
        {
          path: "/",
          name: "Home",
        },

        {
          path: "/my-Order",
          name: "myOrders",
        },
      ],
      orderDetailopenStaus: false,
      orderDetails: [],
      web_admin_url: "",
      currency: "",
      ajax_status:'closed',
      review_open_status:false,
      review_product_url_key:'',
    };
  },
  mounted() {
    this.getCustomerOrders();
    this.web_admin_url = Config.WEB_ADMIN_URL;
    this.currency = Config.CURRECNCY;
    helper.backtoTop();
    let orderId=this.$route.query.orid;
    if(orderId){
      this.openOrderDet(orderId);
    }
    
  },
  computed: {
    ...mapGetters(["fetctCustomerOrderlist"]),
  },
  methods: {
       toggledashboard()
    {
       var x = document.getElementById("vdasboard");
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
    },
    ...mapActions(["getCustomerOrders", "getCustOrderDetail"]),
    topToBottom(){
       helper.backtoTop();
    },
    openOrderDet(orderid) {
      $(".menu-overlay").addClass("active-overlay");
      this.orderDetailopenStaus = true;

      this.getOrderDetails(orderid);
    },
    getOrderDetails(orderid) {
      let $this = this;
      this.ajax_status ='open';
       $this.orderDetails=[];
      this.getCustOrderDetail(orderid).then(function(response) {
      $this.orderDetails = response.data.Data;
      $this.ajax_status ='closed';
      });
    },
    closeOrderDetail() {
      this.orderDetailopenStaus = false;
      $(".menu-overlay").removeClass("active-overlay");
    },
    openOrderReview(urlKey){
            this.review_open_status=true;
            this.review_product_url_key=urlKey;
            
        },
    close_review_popup(){
       this.review_open_status=close;
    }    
    
 
  },
};
</script>
<style scoped></style>
